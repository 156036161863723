// extracted by mini-css-extract-plugin
export var formWrapper = "form-module--formWrapper--209_t";
export var zzz = "form-module--zzz--2h-fa";
export var detailsWrapper = "form-module--detailsWrapper--29eW8";
export var formItem = "form-module--formItem--2rvtX";
export var description = "form-module--description--1tZPc";
export var formActions = "form-module--formActions--1gBtM";
export var button = "form-module--button--2v2Pg";
export var formRequired = "form-module--formRequired--1lPw1";
export var formTextareaWrapper = "form-module--formTextareaWrapper--so7j1";
export var throbber = "form-module--throbber--afpJn";
export var label = "form-module--label--kMgXO";