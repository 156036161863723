// extracted by mini-css-extract-plugin
export var form = "user-edit-module--form--2o26Q";
export var zzz = "user-edit-module--zzz--20a-A";
export var invisible = "user-edit-module--invisible--2fezn";
export var confirmParent = "user-edit-module--confirmParent--1yoPy";
export var passwordParent = "user-edit-module--passwordParent--13ycS";
export var passwordConfirm = "user-edit-module--passwordConfirm--25aMe";
export var passwordField = "user-edit-module--passwordField--1dc-m";
export var passwordStrength = "user-edit-module--passwordStrength--dVmxF";
export var passwordConfirmMatch = "user-edit-module--passwordConfirmMatch--2cZsO";
export var passwordStrengthMeter = "user-edit-module--passwordStrengthMeter--3AKHa";
export var passwordStrengthIndicator = "user-edit-module--passwordStrengthIndicator--1WcMW";
export var passwordsMatch = "user-edit-module--passwordsMatch--cPNUp";
export var passwordsNotMatch = "user-edit-module--passwordsNotMatch--1g9Lr";
export var passwordWeak = "user-edit-module--passwordWeak--32rxG";
export var passwordFair = "user-edit-module--passwordFair--2jCRc";
export var passwordGood = "user-edit-module--passwordGood--2ZJLL";
export var passwordStrong = "user-edit-module--passwordStrong--SrljX";
export var passwordSuggestions = "user-edit-module--passwordSuggestions--16PLT";